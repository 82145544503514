import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useState } from 'react';
import { listBcSettlementsApi } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import DateRangePickerComponent from 'views/Components/DateRangePicker';
const breadCrumbRoutes = [{ label: 'Branch Settlement List', path: '' }];
const columns = [
    { field: 'id', headerName: 'No.', width: 80, valueGetter: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
    {
        field: 'updated_at',
        headerName: 'Date',
        width: 180,
        valueFormatter: (params) => {
            if (!params.value) return 'N/A';
            const date = new Date(params.value);
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, '0')}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
        }
    },
    { field: 'bcname', headerName: 'BC Name', width: 150, valueGetter: (params) => params.value || 'N/A' },
    { field: 'amount', headerName: 'Amount', width: 120, valueGetter: (params) => params.value || 'N/A' },
    { field: 'agentCode', headerName: 'Agent Code', width: 120, valueGetter: (params) => params.value || 'N/A' },
    { field: 'rrn', headerName: 'RRN', width: 150, valueGetter: (params) => params.value || 'N/A' },
    { field: 'mode', headerName: 'Mode', width: 120, valueGetter: (params) => params.value || 'N/A' },
    { field: 'bankname', headerName: 'Bank', width: 150, valueGetter: (params) => params.value || 'N/A' },
    { field: 'status', headerName: 'Status', width: 120, valueGetter: (params) => params.value || 'N/A' }
];
const BranchSettlementList = () => {
    const [dates, setDates] = useState({ from: null, to: null });
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    //handlers>>>
    const handleDateChange = ({ fromDate, toDate }) => {
        setDates({ from: fromDate, to: toDate });
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const modifyData = (previousData) => {
        const updatedData = previousData?.map((item) => ({
            ...item,
            created_at: formatDate(item.created_at)
        }));
        return updatedData;
    };
    //api calls>>>
    const getSettlementsList = async () => {
        setLoading(true);
        const payload = { from: dates.from, to: dates.to };
        const response = await listBcSettlementsApi(payload);
        if (response?.status === 200) {
            const list = response?.data?.list;
            const modifiedData = modifyData(list);
            setTableData(modifiedData);
        }
        setLoading(false);
    };
    useEffect(() => {
        if (dates.from && dates.to) {
            getSettlementsList();
        }
        // eslint-disable-next-line
    }, [dates]);
    return (
        <>
            <BreadcrumbComponent routes={breadCrumbRoutes} />
            <DateRangePickerComponent onDateChange={handleDateChange} />
            <div style={{ marginBottom: 4 }}></div>
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',

                    borderRadius: 2,
                    boxShadow: 2,
                    marginBottom: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid loading={loading} rows={tableData} columns={columns} />
                </div>
            </Box>
        </>
    );
};
export default BranchSettlementList;

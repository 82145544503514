import { useState } from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Paper,
    Typography,
    Button,
    TextField,
    Box,
    Container,
    IconButton,
    Card,
    CardContent,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { PhotoCamera, NavigateNext, NavigateBefore, CheckCircle, Cancel } from '@mui/icons-material';
import styles from './VerifyAgent.module.css';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { physicalVerificationApi, physicalVerificationOtpSendApi } from 'utils/API/Distributor APIs/DistributorAPiCalls';
import { LoadingButton } from '@mui/lab';
import OtpVerificationDialog from 'views/Components/Dialogs/OTPverificationDialog';
const breadcrumbRoutes = [{ label: 'BC Verification', path: '' }];

const AgentVerificationForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        nameAddressVerification: '',
        nameAddressRemark: '',
        shopImage: null,
        shopImageRemark: '',
        mccVerification: '',
        mccRemark: '',
        deviceVerification: '',
        deviceRemark: '',
        trainingVerification: '',
        trainingRemark: '',
        selfieImage: null,
        selfieRemark: ''
    });
    const [otpDialogOpen, setOtpDialogOpen] = useState(false);
    const [otpLoading, setOtpLoading] = useState({ sending: false, verifying: false });

    const agentData = location?.state?.data;

    //api call functions>>>>
    const handleSubmitData = async () => {
        setOtpLoading({ sending: true, verifying: false });
        const payload = {
            address_remark: formData?.nameAddressRemark,
            mcc_remark: formData?.mccRemark,
            device_remark: formData?.deviceRemark,
            training_remark: formData?.trainingRemark,
            agentcode: agentData.agent_code,
            verify_selfieImage: formData?.selfieImage,
            verify_shopImage: formData?.shopImage
        };

        const response = await physicalVerificationOtpSendApi(payload);
        if (response?.status === 200) {
            globalNotifySuccess(`An OTP has been sent to the agent's registered phone number. Kindly verify to proceed.`);
            setOtpDialogOpen(true);
        }
        setOtpLoading({ sending: false, verifying: false });
    };
    const verifyOtp = async (otp) => {
        setOtpLoading({ sending: false, verifying: true });
        const payload = {
            address_remark: formData?.nameAddressRemark,
            mcc_remark: formData?.mccRemark,
            device_remark: formData?.deviceRemark,
            training_remark: formData?.trainingRemark,
            agentcode: agentData.agent_code,
            verify_selfieImage: formData?.selfieImage,
            verify_shopImage: formData?.shopImage,
            otp
        };
        const response = await physicalVerificationApi(payload);
        if (response?.status === 200) {
            globalNotifySuccess('Verification data has been submitted successfully');
            navigate('/employee/nearbyAgents');
        }
        setOtpLoading({ sending: false, verifying: false });
    };
    const handleOtpClose = () => {
        setOtpDialogOpen(false);
    };
    const steps = isMobile
        ? ['Details', 'Shop', 'MCC', 'Device', 'Training', 'Selfie']
        : ['Verify Details', 'Shop Image', 'MCC Verification', 'Device Verification', 'Training Verification', 'Selfie Verification'];

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value
        }));
    };
    const handleImageUpload = (field, event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                // Check file size (optional)
                if (file.size > 5 * 1024 * 1024) {
                    globalNotifyError('Image size should be less than 5MB');
                    return;
                }
                // Convert image to base64
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFormData((prev) => ({
                        ...prev,
                        [field]: reader.result, // Store base64 string
                        [`${field}FileName`]: file.name // Store filename if needed
                    }));
                };
                reader.readAsDataURL(file);
            } else {
                alert('Please upload an image file');
            }
        }
    };
    const getImagePreview = (field) => {
        return formData[field] || null;
    };

    const isStepComplete = (step) => {
        const imageSteps = [1, 5];
        const verificationFields = {
            0: 'nameAddressVerification',
            1: 'shopImage',
            2: 'mccVerification',
            3: 'deviceVerification',
            4: 'trainingVerification',
            5: 'selfieImage'
        };
        const remarkFields = {
            0: 'nameAddressRemark',
            1: 'shopImageRemark',
            2: 'mccRemark',
            3: 'deviceRemark',
            4: 'trainingRemark',
            5: 'selfieRemark'
        };

        const field = verificationFields[step];
        const remarkField = remarkFields[step];
        if (imageSteps.includes(step)) {
            // Only check if image exists
            return !!formData[field];
        }

        return formData[field] && (formData[field] === 'yes' || formData[remarkField]);
    };

    const handleNext = () => {
        setActiveStep((prev) => prev + 1);
    };

    const handleBack = () => {
        setActiveStep((prev) => prev - 1);
    };
    const YesNoButtonGroup = ({ value, onChange }) => (
        <Box className={styles.buttonGroupWrapper}>
            <Button
                variant="contained"
                className={`${styles.selectionButton} ${value === 'yes' ? styles.selectedYes : styles.unselected}`}
                onClick={() => onChange('yes')}
            >
                <CheckCircle sx={{ mr: 1 }} />
                Yes
            </Button>
            <Button
                variant="contained"
                className={`${styles.selectionButton} ${value === 'no' ? styles.selectedNo : styles.unselected}`}
                onClick={() => onChange('no')}
            >
                <Cancel sx={{ mr: 1 }} />
                No
            </Button>
        </Box>
    );
    const renderImageUploadStep = (field, title, instructions) => (
        <Box className={styles.stepContent}>
            <Typography variant="h6" className={styles.stepHeader}>
                {title}
            </Typography>
            {instructions && <Box className={styles.instructions}>{instructions}</Box>}
            <input
                type="file"
                accept="image/*"
                id={`${field}-upload`}
                hidden
                onChange={(e) => handleImageUpload(field, e)}
                capture={field === 'selfieImage' ? 'user' : 'environment'}
            />
            <label htmlFor={`${field}-upload`}>
                <Box className={styles.imageUploadBox}>
                    {formData[field] ? (
                        <Box className={styles.previewContainer}>
                            <img src={getImagePreview(field)} alt="Preview" className={styles.imagePreview} />
                            <Typography className={styles.uploadText}>Tap to re-upload photo</Typography>
                        </Box>
                    ) : (
                        <>
                            <IconButton color="primary" component="span">
                                <PhotoCamera className={styles.cameraIcon} />
                            </IconButton>
                            <Typography className={styles.uploadText}>Tap to take photo</Typography>
                        </>
                    )}
                </Box>
            </label>
            {formData[field] && <Typography className={styles.successText}>Image captured successfully</Typography>}
        </Box>
    );
    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box className={styles.stepContent}>
                        <Typography variant="h6" className={styles.stepHeader}>
                            Verify Agent Details
                        </Typography>
                        <Box className={styles.agentInfoBox}>
                            <Typography variant="subtitle2" gutterBottom>
                                Agent Code: {agentData?.agent_code}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Name: {agentData?.full_name}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Address: {agentData?.address}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Shop Name: {agentData?.shop_name}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Shop Address: {agentData?.shop_address}
                            </Typography>
                        </Box>
                        <Typography variant="body2" className={styles.stepQuestion}>
                            Are the BC Agent details correct?
                        </Typography>
                        <YesNoButtonGroup
                            value={formData.nameAddressVerification}
                            onChange={(value) => handleInputChange('nameAddressVerification', value)}
                        />
                        {formData.nameAddressVerification === 'no' && (
                            <TextField
                                className={styles.remarksField}
                                multiline
                                rows={3}
                                variant="outlined"
                                label="Remarks"
                                size="small"
                                value={formData.nameAddressRemark}
                                onChange={(e) => handleInputChange('nameAddressRemark', e.target.value)}
                            />
                        )}
                    </Box>
                );

            case 1:
                return renderImageUploadStep(
                    'shopImage',
                    'Shop Image Upload',
                    <Box>
                        <Typography variant="body2" gutterBottom>
                            Instructions:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            1. The agent must stand in front of the shop while the image is taken.
                        </Typography>
                        <Typography variant="body2">
                            2. Use a time-stamp camera to capture the image. The photo must clearly show the location and the time stamp on
                            it.
                        </Typography>
                    </Box>
                );
            case 2:
                return (
                    <Box className={styles.stepContent}>
                        <Typography variant="h6" className={styles.stepHeader}>
                            MCC Verification
                        </Typography>
                        <Typography variant="body2" className={styles.stepQuestion}>
                            {agentData.mcc ? `Shop MCC is ${agentData.mcc}` : 'MCC not available'}
                        </Typography>
                        <Typography variant="body2" className={styles.stepQuestion}>
                            {agentData.mcc_name ? `MCC Name: ${agentData.mcc_name}` : 'MCC name not available'}
                        </Typography>
                        <YesNoButtonGroup
                            value={formData.mccVerification}
                            onChange={(value) => handleInputChange('mccVerification', value)}
                        />
                        {formData.mccVerification === 'no' && (
                            <TextField
                                className={styles.remarksField}
                                multiline
                                rows={4}
                                variant="outlined"
                                label="Remarks"
                                value={formData.mccRemark}
                                onChange={(e) => handleInputChange('mccRemark', e.target.value)}
                            />
                        )}
                    </Box>
                );

            case 3:
                return (
                    <Box className={styles.stepContent}>
                        <Typography variant="h6" className={styles.stepHeader}>
                            Device Verification
                        </Typography>
                        <Typography variant="h6">Does the agent have a mATM/mPOS Device in hand?</Typography>
                        <YesNoButtonGroup
                            value={formData.deviceVerification}
                            onChange={(value) => handleInputChange('deviceVerification', value)}
                        />
                        {formData.deviceVerification === 'no' && (
                            <TextField
                                className={styles.remarksField}
                                multiline
                                rows={4}
                                variant="outlined"
                                label="Remarks"
                                value={formData.deviceRemark}
                                onChange={(e) => handleInputChange('deviceRemark', e.target.value)}
                            />
                        )}
                    </Box>
                );

            case 4:
                return (
                    <Box className={styles.stepContent}>
                        <Typography variant="h6" className={styles.stepHeader}>
                            Training Verification
                        </Typography>
                        <Typography variant="h6">
                            Are the agents trained by the onboarding employees and knowledgeable about using the Acemoney Business
                            application?
                        </Typography>
                        <YesNoButtonGroup
                            value={formData.trainingVerification}
                            onChange={(value) => handleInputChange('trainingVerification', value)}
                        />
                        {formData.trainingVerification === 'no' && (
                            <TextField
                                className={styles.remarksField}
                                multiline
                                rows={4}
                                variant="outlined"
                                label="Remarks"
                                value={formData.trainingRemark}
                                onChange={(e) => handleInputChange('trainingRemark', e.target.value)}
                            />
                        )}
                    </Box>
                );

            case 5:
                return renderImageUploadStep(
                    'selfieImage',
                    'Selfie Verification',
                    <Box>
                        <Typography variant="body2" gutterBottom>
                            Instructions:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            1. Stand in front of the shop.
                        </Typography>
                        <Typography variant="body2">2. Use a time-stamp camera to capture the location and time.</Typography>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <BreadcrumbComponent routes={breadcrumbRoutes} />
            <Container className={styles.formContainer} disableGutters>
                <Paper elevation={0}>
                    <Box className={styles.stepperContainer}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel className={styles.stepLabel}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    <Card className={styles.contentCard}>
                        <CardContent>{renderStepContent(activeStep)}</CardContent>
                    </Card>

                    <Box className={styles.buttonGroup}>
                        <Button
                            variant="outlined"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            startIcon={<NavigateBefore />}
                            className={styles.fullWidthButton}
                            size="large"
                        >
                            Back
                        </Button>
                        {activeStep === steps.length - 1 ? (
                            <LoadingButton
                                variant="contained"
                                color="success"
                                onClick={handleSubmitData}
                                disabled={!isStepComplete(activeStep)}
                                endIcon={<CheckCircle />}
                                className={styles.fullWidthButton}
                                size="large"
                                loading={otpLoading.sending}
                            >
                                Verify
                            </LoadingButton>
                        ) : (
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                disabled={!isStepComplete(activeStep)}
                                endIcon={<NavigateNext />}
                                className={styles.fullWidthButton}
                                size="large"
                            >
                                Next
                            </Button>
                        )}
                    </Box>
                </Paper>
            </Container>
            <OtpVerificationDialog
                open={otpDialogOpen}
                onSubmit={verifyOtp}
                verifying={otpLoading?.verifying}
                resending={otpLoading?.sending}
                onResend={handleSubmitData}
                onClose={handleOtpClose}
            />
        </>
    );
};

export default AgentVerificationForm;

AgentVerificationForm.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string
};

import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { listmytransactionsApi } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button } from '@mui/material';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import { handleExcelDownloadGlobal } from 'utils/exports/excelDownload';
const breadCrumbRoutes = [{ label: 'Wallet Transactions', path: '' }];
const MyTransactions = () => {
    const today = dayjs().format('YYYY/MM/DD');
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [rowCount, setRowCount] = useState(0);
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    //api calls>>>
    const getMyTransactions = async () => {
        setLoading(true);
        const payload = { fromdate: fromDate, todate: toDate, start: page, length: pageSize };
        const response = await listmytransactionsApi(payload);
        if (response?.status === 200) {
            const dataList = response?.data?.data;
            setTransactions(dataList);
            setRowCount(response?.data?.datacount);
        }
        setLoading(false);
    };
    //handler functions>>>
    const handleFromDateChange = (newValue) => {
        setFromDate(newValue ? dayjs(newValue).format('YYYY/MM/DD') : null);
    };

    const handleToDateChange = (newValue) => {
        setToDate(newValue ? dayjs(newValue).format('YYYY/MM/DD') : null);
    };
    // Handle page size change
    const handlePageSizeChange = (params) => {
        setPage(params.page + 1);
        setPageSize(params?.pageSize);
    };

    useEffect(() => {
        getMyTransactions();
        // eslint-disable-next-line
    }, [fromDate, toDate, page, pageSize]);
    const columns = [
        {
            field: 'index',
            headerName: 'No.',
            width: 60,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 // Calculate row index (starting from 1)
        },
        {
            field: 'created_at',
            headerName: 'Date',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                const date = new Date(params.value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (1 + date.getMonth()).toString().padStart(2, '0');
                const year = date.getFullYear();
                let hour = date.getHours();
                const minute = date.getMinutes().toString().padStart(2, '0');
                const meridiem = hour >= 12 ? 'PM' : 'AM';
                hour = hour % 12 || 12; // Convert hour to 12-hour format
                return `${day}-${month}-${year} ${hour}:${minute} ${meridiem}`;
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 250,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'TxnId',
            headerName: 'Transaction ID',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'isCredit',
            headerName: 'Credit Status',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: params.value === 'Debit' ? 'blue' : 'red' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'balance',
            headerName: 'Balance',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        }
    ];
    return (
        <>
            <BreadcrumbComponent routes={breadCrumbRoutes} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        boxShadow: 2,
                        marginBottom: 2,
                        padding: '20px 10px',
                        '@media screen and (min-width: 600px)': {
                            padding: 2
                        }
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <DatePicker
                            label="From Date"
                            value={fromDate ? dayjs(fromDate, 'YYYY/MM/DD') : null}
                            onChange={handleFromDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        <DatePicker
                            label="To Date"
                            value={toDate ? dayjs(toDate, 'YYYY/MM/DD') : null}
                            onChange={handleToDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<FileDownloadIcon />}
                            onClick={() => handleExcelDownloadGlobal(columns, transactions, 'WALLET_TRANSACTIONS')}
                        >
                            Export to Excel
                        </Button>
                    </Box>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={transactions}
                            columns={columns}
                            pagination
                            page={page}
                            getRowId={(row) => row.id}
                            paginationMode="server"
                            rowCount={rowCount}
                            rowsPerPageOptions={[25, 50, 100]}
                            pageSize={pageSize}
                            // onPageChange={handlePageChange}
                            onPaginationModelChange={(params) => handlePageSizeChange(params)}
                            loading={loading}
                            // slots={{ toolbar: GridToolbar }}
                        />
                    </div>
                </Box>
            </LocalizationProvider>
        </>
    );
};

export default MyTransactions;
